<template>
  <div>
    <div class="bgBox">
      <el-carousel arrow="never" indicator-position="none">
        <el-carousel-item v-for="img in bglist" :key="img.id">
          <img class="img" :src="img.url" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="bgTitle">
        <div>在线诚招</div>
        <div>欢迎心怀梦想的你，加入一个简单高效的新团队一起奋斗</div>
      </div>
    </div>
    <div>
      <div class="mainBox">
        <div class="mainauto">
          <div>
            <div class="title">和我们一起成长</div>
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col
                ><div class="grid-content bg-purple">
                  <div class="about_img_div">
                    <img
                      class="tuandui"
                      @mouseover="changeActive($event)"
                      @mouseleave="removeActive($event)"
                      src="@/assets/aboutus/fzjh_img.jpg"
                      alt=""
                    />
                  </div>

                  <div class="bootom_text">
                    <img src="@/assets/aboutus/fzjh_icon.svg" alt="" />发展计划
                  </div>
                  <div class="bottom_text1">
                    我们为新伙伴提供各种发展机会，发挥个人潜能，同时为你提供各种培训和学习机会，帮助你全方位提升职业能力。
                  </div>
                </div></el-col
              >
              <el-col
                ><div class="grid-content bg-purple">
                  <div class="about_img_div">
                    <img
                      class="tuandui"
                      @mouseover="changeActive($event)"
                      @mouseleave="removeActive($event)"
                      src="@/assets/aboutus/gsfi_img.png"
                      alt=""
                    />
                  </div>

                  <div class="bootom_text">
                    <img src="@/assets/aboutus/gsfi_icon.svg" alt="" />发展计划
                  </div>
                  <div class="bottom_text1">
                    入职签订劳动合同，缴纳五险一金，餐饮补贴、员工旅游、节庆关怀、全勤奖、分公司学习等各类福利。
                  </div>
                </div></el-col
              >
              <el-col
                ><div class="grid-content bg-purple">
                  <div class="about_img_div">
                    <img
                      class="tuandui"
                      @mouseover="changeActive($event)"
                      @mouseleave="removeActive($event)"
                      src="@/assets/aboutus/czts_img.png"
                      alt=""
                    />
                  </div>

                  <div class="bootom_text">
                    <img src="@/assets/aboutus/czts_icon.svg" alt="" />发展计划
                  </div>
                  <div class="bottom_text1">
                    我们为每位新入职伙伴配备了成长天使，让优秀老员工通过为期3个月的悉心带领辅导和交流，带领你迅速融入公司并一起成长。
                  </div>
                </div></el-col
              >
            </el-row>
          </div>
          <div>
            <div class="title">团队定期举办团建活动</div>
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col
                ><div class="grid-content bg-purple">
                  <div class="about_img_div">
                    <img
                      class="tuandui"
                      @mouseover="changeActive($event)"
                      @mouseleave="removeActive($event)"
                      src="@/assets/aboutus/tuandui1.png"
                      alt=""
                    />
                  </div></div
              ></el-col>
              <el-col
                ><div class="grid-content bg-purple">
                  <div class="about_img_div">
                    <img
                      class="tuandui"
                      @mouseover="changeActive($event)"
                      @mouseleave="removeActive($event)"
                      src="@/assets/aboutus/tuandui2.png"
                      alt=""
                    />
                  </div></div
              ></el-col>
              <el-col
                ><div class="grid-content bg-purple">
                  <div class="about_img_div">
                    <img
                      class="tuandui"
                      @mouseover="changeActive($event)"
                      @mouseleave="removeActive($event)"
                      src="@/assets/aboutus/tuandui3.png"
                      alt=""
                    />
                  </div></div
              ></el-col>
            </el-row>
          </div>
          <form-submit></form-submit>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSubmit from "./FormSubmit";
export default {
  components: { FormSubmit },
  name: "fresh-talent",
  data() {
    return {
      bglist: [{ id: 0, url: require("@/assets/aboutus/freshtalent_bg1.jpg") }],
    };
  },
  methods: {
    changeActive($event) {
      $event.currentTarget.className = "tuanduis";
    },
    removeActive($event) {
      $event.currentTarget.className = "tuandui";
    },
  },
};
</script>

<style scoped>
.bgBox {
  width: 100%;
  position: relative;
}
.bgBox >>> .el-carousel__container {
  height: 700px;
}
.img {
  width: 100%;
  height: 100%;
}
.bgTitle {
  min-width: 700px;
  position: absolute;
  color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  line-height: 50px;
  text-align: center;
}
.bgTitle > div:nth-child(1) {
  font-size: 48px;
  margin-bottom: 20px;
}
.bgTitle > div:nth-child(2) {
  font-size: 38px;
}
.mainBox {
  overflow: hidden;
  padding-bottom: 180px;
}
.mainauto {
  width: 1200px;
  margin: 0 auto;
}
.title {
  font-size: 38px;
  color: #404040;
  padding: 56px 0;
  text-align: center;
}
.row-bg .grid-content {
  width: 96%;
}
.about_img_div {
  overflow: hidden;
  height: 325px;
  position: relative;
}
.about_img_div .tuandui {
  width: 100%;
  height: 100%;
  transition: 0.1s;
  position: absolute;
  left: 0;
  top: 0;
}
.about_img_div .tuanduis {
  width: 120%;
  height: 116%;
  transition: 0.1s;
  position: absolute;
  left: -38px;
  top: -26px;
}
.bootom_text {
  display: flex;
  padding: 20px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 25px;
  color: #fc4b42;
}
.bootom_text img {
  margin-right: 15px;
}
.bottom_text1 {
  padding: 0 20px 20px 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 28px;
  color: #707070;
}
</style>